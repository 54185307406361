<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
export default {
    props: {
        series: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'line', // Tipo de gráfico
                    backgroundColor: "#f0f8ff"
                },
                title: {
                    text: 'Tendencia de procesos',
                },
                accessibility: {
                    enabled: false // Desactiva accesibilidad
                },
                xAxis: {
                    categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                },
                yAxis: {
                    title: {
                        text: 'Porcentaje',
                    },
                },
                series: []
            },
        };
    },
    watch: {
        // Observa cambios en 'series' para actualizar el gráfico
        series: {
            handler(newSeries) {
                this.chartOptions.series = newSeries;
            },
            immediate: true, // Ejecuta al inicializar el componente
            deep: true
        }
    }
};
</script>
