<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar', // Tipo de gráfico: barras
        },
        accessibility: {
          enabled: false,  // Deshabilitar la accesibilidad
        },
        title: {
          text: 'Distribución de Ventas', // Título del gráfico
        },
        xAxis: {
          categories: ['Producto A', 'Producto B', 'Producto C', 'Producto D'], // Categorías del eje X
          title: {
            text: 'Productos', // Título del eje X
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Cantidad Vendida',
            align: 'high',
          },
          labels: {
            overflow: 'justify',
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true, // Muestra etiquetas con los valores
            },
          },
        },
        series: [
          {
            name: '2024', // Nombre de la serie
            data: [50, 70, 90, 30], // Datos del gráfico
          },
          {
            name: '2023', // Otra serie (opcional)
            data: [40, 60, 80, 20],
          },
        ],
      },
    };
  },
};
</script>