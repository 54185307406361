/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import { createApp } from 'vue';
import HighchartsVue from 'highcharts-vue';

import LoaderComponent from './vue/components/LoaderComponent.vue';

import DashboardIndicatorComponent from './vue/components/Indicadores/DashboardComponent.vue';
import AnalisisIndicatorComponent from './vue/components/Indicadores/AnalisisComponent.vue';

import BarChart from './vue/components/Charts/BarChart.vue';
import LineChart from './vue/components/Charts/LineChart.vue';
import DonutChar from './vue/components/Charts/DonutChart.vue';
import TimeChart from './vue/components/Charts/TimeChart.vue';

const app = createApp({});

app.use(HighchartsVue);

app.component('loader-component', LoaderComponent);

app.component('dashboard-indicator-component', DashboardIndicatorComponent);
app.component('analisis-indicator-component', AnalisisIndicatorComponent);
app.component('bar-chart', BarChart);
app.component('line-chart', LineChart);
app.component('donut-chart', DonutChar);
app.component('time-chart', TimeChart);

if(document.getElementById('app')){
    app.mount('#app');
}