<template>
    <div>
        <highcharts :options="chartOptions" ref="chartComponent"></highcharts>
    </div>
</template>

<script>
export default {
    props: {
        series: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'pie',
                    backgroundColor: "#fff",
                    events: {
                        load() {
                            const chart = this; // 'this' es el gráfico de Highcharts
                            // Llamar a la función que actualiza el texto centrado
                            if (chart.customUpdateCenterText) {
                                chart.customUpdateCenterText();
                            }
                        },
                    },
                },
                title: {
                    text: '',
                },
                tooltip: {
                    enabled: false,
                },
                accessibility: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        innerSize: '60%',
                        dataLabels: {
                            enabled: false,
                        },
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                },
                series: [], // Inicialmente vacío
            },
        };
    },
    mounted() {
        // Acceso al gráfico y asignación de la función de actualización
        this.$nextTick(() => {
            const chart = this.$refs.chartComponent.chart; // Referencia al gráfico
            if (chart) {
                chart.customUpdateCenterText = () => this.updateCenterText(chart);
                this.updateCenterText(chart); // Llamar la primera vez
            }
        });
    },
    methods: {
        updateCenterText(chart) {
            // Verificar si el gráfico existe
            if (!chart) return;

            // Eliminar texto previo, si existe
            if (chart.centerText) {
                chart.centerText.destroy();
            }

            const reached = chart.series[0]?.data[0]?.y || 0; // Valor alcanzado
            const total = chart.series[0]?.data.reduce((sum, point) => sum + point.y, 0) || 0;
            const percentage = total > 0 ? ((reached / total) * 100).toFixed(0) : 0;

            // Agregar texto al centro del gráfico
            chart.centerText = chart.renderer
                .text(`${percentage}%`, chart.plotLeft + chart.plotWidth / 2, chart.plotTop + chart.plotHeight / 2)
                .attr({
                    align: 'center',
                    zIndex: 5,
                })
                .css({
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                })
                .add();
        },
    },
    watch: {
        series: {
            handler(newSeries) {
                this.chartOptions.series = newSeries; // Actualizar las series

                // Actualizar el texto centrado después de los cambios
                this.$nextTick(() => {
                    const chart = this.$refs.chartComponent.chart;
                    this.updateCenterText(chart);
                });
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>