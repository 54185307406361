<template>
    <div class="row d-flex align-items-center">
        <div class="col-md-3">
            <div class="form-group">
                <label for="system">Sistema</label>
                <select name="system" id="system" class="form-control" v-model="filters.system">
                    <option v-for="system in systems" :key="system.id" :value="system.id">{{ system.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="area">Area</label>
                <select @change="fetchProcessByArea" name="area" id="area" class="form-control" v-model="filters.area">
                    <option v-for="area in areas" :key="area.id" :value="area.id">{{ area.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="process">Proceso</label>
                <select name="process" id="process" class="form-control" v-model="filters.process">
                    <option value="">Todos</option>
                    <option v-for="procs in process" :key="procs.id" :value="procs.id">{{ procs.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="year">Año</label>
                <select name="year" id="year" class="form-control" v-model="filters.year">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="month">Mes</label>
                <select name="month" id="month" class="form-control" v-model="filters.month">
                    <option value="">Todos</option>
                    <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group pt-0 pt-md-5">
                <button @click="fetchData" class="btn btn-md btn-primary" v-if="filters.system && filters.area">Filtrar</button>
            </div>
        </div>
    </div>
    <div class="row pt-4" v-if="seriesChart.length <= 0">
        <div class="col-md-12 text-center">
            <strong>Sin resultados</strong>
        </div>
    </div>
    <div class="row pt-4 py-5" v-else>
        <div class="col-md-4">
            <line-chart :series="seriesChart.series_line"/>
        </div>
        <div class="col-md-4">
            <time-chart :series="seriesChart"/>
        </div>
        <div class="col-md-4">
            <donut-chart v-for="(donut, index) in seriesChart.series_donut" :key="index" :series="seriesChart.series_donut"/>
        </div>
    </div>

    <loader-component v-if="loading" />
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                filters: {
                    system: "",
                    area: "",
                    process: "",
                    year: "",
                    month: ""
                },
                systems: [],
                areas: [],
                process: [],
                range: 5,
                loading: true,
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                seriesChart: []
            };
        },
        computed:{
            years(){
                const currentYear = new Date().getFullYear(); // Obtiene el año actual
                return Array.from({ length: this.range + 1 }, (_, i) => currentYear + i); // Genera los años
            }
        },
        mounted() {
            this.fetchSystems()
            this.fetchAreas()
        },
        methods: {
            async fetchSystems(){
                await axios.get('/systems')
                    .then(response => {
                        this.systems = response.data;
                        this.loading = false
                    })
                    .catch(error => {
                        this.loading = false
                        console.error('Error fetching data:', error);
                    });
            },
            async fetchAreas() {
                await axios.get('/areas')
                    .then(response => {
                        this.areas = response.data;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            },
            async fetchProcessByArea() {
                await axios.get('/area/process', {params: {'area' : this.filters.area} })
                    .then(response => {
                        this.process = response.data.data;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            },
            async fetchData() {
                this.loading = true;
                await axios.get('/indicator/dashboard', {params: this.filters})
                    .then(response => {
                        this.seriesChart = response.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        this.loading = false;
                    });
            }
        }
    };
</script>