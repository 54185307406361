<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <button class="btn btn-primary mb-3" @click="abrirModal(null)">Nuevo Registro</button>
            <table class="table datagrid">
                <thead class="table-light">
                    <tr>
                        <th class="py-3">ID</th>
                        <th class="header-for-field-id text-left">Descripción del incumplimiento</th>
                        <th class="header-for-field-id text-left">Acción a realizar</th>
                        <th>Responsable</th>
                        <th>Fecha</th>
                        <th>% Avance</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody v-if="data.length > 0">
                    <tr v-for="(analisis, index) in data" :key="analisis.id">
                        <td>{{ analisis.id }}</td>
                        <td>{{ analisis.descripcion }}</td>
                        <td>{{ analisis.accion }}</td>
                        <td>{{ analisis.responsable }}</td>
                        <td>{{ analisis.fecha }}</td>
                        <td>{{ analisis.avance }}</td>
                        <td>
                            <div class="dropdown">
                                <button class="btn btn-secondary btn-sm dropdown-toggle" type="button"
                                    id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots-vertical"></i> <!-- Icono de tres puntos -->
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><a class="dropdown-item" @click="abrirModal(analisis)">Modificar</a></li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li><a class="dropdown-item text-danger"
                                            @click="confirmDelete(analisis.id)">Borrar</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-center" colspan="7">Sin registros</td>
                    </tr>
                </tbody>
            </table>

            <!-- Modal para Agregar/Editar -->
            <div class="modal fade show" v-if="mostrarModal" tabindex="-1"
                style="display: block; background-color: rgba(0, 0, 0, 0.5);">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{ modalRegistro.id ? "Editar Registro" : "Nuevo Registro" }}
                            </h5>
                            <button type="button" class="btn-close" @click="cerrarModal"></button>
                        </div>
                        <div class="modal-body">
                            <form @submit.prevent="saveUpdateAnalisis">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="descripcion" class="form-label">Descripción del
                                                incumplimiento</label>
                                            <textarea class="form-control" id="descripcion"
                                                v-model="modalRegistro.descripcion" rows="2" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="accion" class="form-label">Acción a realizar</label>
                                            <textarea class="form-control" id="accion" v-model="modalRegistro.accion"
                                                rows="2"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="responsable" class="form-label">Responsable</label>
                                            <select multiple class="form-control" id="responsable"
                                                v-model="modalRegistro.responsable" required>
                                                <option v-for="user in users" :key="user.id" :value="user.id">{{
                                                    user.nombre }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="fecha" class="form-label">Fecha</label>
                                            <input type="date" class="form-control" id="fecha"
                                                v-model="modalRegistro.fecha" required>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="avance" class="form-label">Avance</label>
                                            <input type="number" class="form-control" id="avance"
                                                v-model="modalRegistro.avance" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="cerrarModal">
                                        Cancelar
                                    </button>
                                    <button type="submit" class="btn btn-primary">Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal para Confirmación de Eliminación -->
            <div class="modal fade show" id="confirmEliminarModal" v-if="modalDelete"  tabindex="-1"
                style="display: block; background-color: rgba(0, 0, 0, 0.5);">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="confirmEliminarModalLabel">Confirmar Eliminación</h5>
                            <button type="button" class="btn-close" @click="cerrarModalDelete"></button>
                        </div>
                        <div class="modal-body">
                            ¿Estás seguro de que deseas eliminar este registro? Esta acción no se puede deshacer.
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModalDelete">Cancelar</button>
                            <button type="button" class="btn btn-danger" @click="deleteAnalisis">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>

            <!--<ConfirmDialog v-if="registroEliminar" :show="true" @confirm="eliminarRegistro" @cancel="registroEliminar = null" />-->
            <!--<IndicadorAnalisisForm v-if="registroEditar" :registro="registroEditar" @save="guardarRegistro" @cancel="registroEditar = null" />-->
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        indicator: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            data: [],
            users: [],
            loading: true,
            modalRegistro: {
                responsable: []
            }, // Registro para agregar o editar
            mostrarModal: false, // Estado del modal
            registroEliminar: null, // Registro a eliminar
            modalDelete: false,
            registroEliminar: null
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchUsers() {
            await axios.get(`/users`)
                .then(response => {
                    this.users = response.data;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        async fetchData() {
            await axios.get(`/indicator/analisis/${this.indicator}`)
                .then(response => {
                    this.data = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    this.loading = false;
                });
        },
        abrirModal(registro) {
            this.modalRegistro = registro
                ? { ...registro }
                : { indicador_id: this.indicator, descripcion: "", accion: "", responsable: [], fecha: "", avance: "" };
            this.mostrarModal = true;

            //if (!registro) {
                this.fetchUsers();
            //}
        },
        cerrarModal() {
            this.modalRegistro = null;
            this.mostrarModal = false;
        },
        async saveUpdateAnalisis() {
            this.loading = true;
            if (this.modalRegistro.id) {
                await axios.put(`/indicator/analisis/${this.modalRegistro.id}`, this.modalRegistro)
                    .then(response => {
                        this.fetchData();  // Refrescar los datos después de la actualización
                        this.cerrarModal(); // Cerrar el modal
                    })
                    .catch(error => {
                        console.error('Error updating data:', error);
                    });
                return;
            }
            await axios.post('/indicator/analisis', this.modalRegistro)
                .then(response => {
                    this.fetchData();
                    this.cerrarModal();
                })
                .catch(error => {
                    console.error('Error save data:', error);
                })
        },
        confirmDelete(id) {
            this.modalDelete = true
            this.registroEliminar = id
        },
        cerrarModalDelete(){
            this.modalDelete = false
            this.registroEliminar = null
        },
        async deleteAnalisis(){
            await axios.delete(`/indicator/analisis/${this.registroEliminar}`)
                .then(response => {
                    this.fetchData();
                    this.cerrarModalDelete();
                })
                .catch(error => {
                    console.error('Error delete row:', error);
                })
        }
    }
};
</script>