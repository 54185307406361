<template>
    <div class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Loading...</p>
    </div>
</template>
  
<style scoped>
  .loading-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);  /* Fondo oscuro y semi-transparente */
    display: flex;
    align-items: center;  /* Centrado vertical */
    justify-content: center;  /* Centrado horizontal */
    z-index: 1050;  /* Asegúrate de que esté sobre los demás elementos */
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3; /* Color gris claro */
    border-top: 4px solid #3498db; /* Color azul para el spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;  /* Animación para girar el spinner */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  p {
    color: white;
    margin-top: 10px;  /* Distancia entre el spinner y el texto */
  }
</style>
  